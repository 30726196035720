<script>
import axios from 'axios';

export default {
  data() {
    return {
      buttonCreateDisabled: false,
      buttonCreateText: 'Create',

      isInvalidLink: false,
      isExistLink: false,
      isInvalidTitle: false,

      title: '',
      short: '',
      link: '',
      message: '',
    }
  },
  components: {
  },
  methods: {
    modalShown() {
    },
    modalHidden() {
    },
    preventEnterKey(){
    },
    validateForm() {
      this.isInvalidLink = !this.link || this.isExistLink;
      this.isInvalidTitle = !this.title;

      if(!(this.isInvalidLink || this.isInvalidTitle))
          return true;
      
      return false;
    },
    checkLinkExist() {
      if(!this.link) {
        this.isExistLink = false;
        this.isInvalidLink = false;
        return false;
      }

      var data = {
        link: this.link
      };

      const vm = this;
      axios.post('/api/management/podcast/exist', data, { withCredentials: true }).then(function (e) {
        vm.isExistLink = e.data.exist;
        vm.isInvalidLink = vm.isExistLink;
      });
    },
    sendCreatePodcastForm() {
      var r = this.validateForm();
      if(r && !this.isExistLink) {
        this.buttonCreateDisabled = true;
        var data = {
          link: this.link,
          title: this.title,
          short: this.short,
          message: this.message
        };

        const vm = this;
        axios.post('/api/management/podcast/create', data, { withCredentials: true }).then(function (e) {
          vm.$refs.closeButton.click();
          vm.$router.push('/podcast/' + e.data.podcast.link);
        });
      }
    }
  },
};
</script>

<template>
  <b-modal @shown="modalShown" @hidden="modalHidden" hide-footer :hide-header-close="true" title="Create new podcast" class="v-modal-custom zoomIn createPodcast" size="lg">
  <form>

    <div class="row g-3">
      <div class="col-12">
        <div>
          <label for="title" class="form-label">Link</label>
          <p class="text-muted">This link will be available to you at https://busycast.com/podcast/{{ link }}</p>
          <input type="text" class="form-control" id="link" placeholder="Your link" v-model="link" maxlength="32" v-bind:class="{ 'is-invalid': isInvalidLink }" v-on:keydown.enter.prevent="preventEnterKey" v-on:blur="checkLinkExist">
          <span class="text-danger" v-if="isExistLink">Link already exists. Try another link.</span>
        </div>
      </div>
      <div class="col-12">
        <div>
          <label for="title" class="form-label">Title</label>
          <input type="text" class="form-control" id="title" placeholder="Podcast title" v-model="title" maxlength="128" v-bind:class="{ 'is-invalid': isInvalidTitle }" v-on:keydown.enter.prevent="preventEnterKey">
        </div>
      </div>
      <div class="col-12">
        <div>
          <label for="title" class="form-label">Short</label>
          <input type="text" class="form-control" id="short" placeholder="Short description" v-model="short" maxlength="160" v-on:keydown.enter.prevent="preventEnterKey">
        </div>
      </div>
      <div class="col-12">
        <div>
          <label for="message" class="form-label">Description</label>
          <textarea id="message" class="form-control" rows="5" placeholder="Full description" v-model="message"></textarea>
        </div>
      </div>
      <div class="col-6"></div>
    </div>

  </form>

  <div class="modal-footer v-modal-footer pb-0 pe-0">
    <button type="button" @click="sendCreatePodcastForm" class="btn btn-primary" v-bind:disabled="buttonCreateDisabled">{{ buttonCreateText }}</button>
    <a href="javascript:void(0);" ref="closeButton" class="btn btn-light fw-medium" data-bs-dismiss="modal">Close</a>
  </div>
  </b-modal>
</template>