<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader";
import appConfig from "../../../app.config";
import Choices from 'choices.js';

import axios from 'axios';
import PodcastItem from "./PodcastItem.vue";
import Create from "./_Create.vue";

export default {
  page: {
    title: "Podcasts",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      data: {
        list: [],
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    PodcastItem,
    Create,
  },
  created: function () {
  },
  updated: function() {
    this.loadingPodcasts();
  },
  mounted: function () {
    this.loadingPodcasts();
  },
  methods: {
    loadingPodcasts() {
      var id = this.$route.params.id;
      var url = '/api/podcasts/list';
      if(id)
        url += '/' + id;
      axios.get(url)
        .then((response) => {
          this.data = response.data;
          this.loadingCategoriesTree();
        })
      .catch(function () {
      });
    },
    loadingCategoriesTree() {
      var tree = new Choices('#choices-podcasts-categories', { allowHTML: true });
      var rootCategories = this.data.categories.filter(cat => cat.id_Parent === "00000000-0000-0000-0000-000000000000");

      const choicesData = rootCategories.map(rootCategory => {
        const childCategories = this.data.categories.filter(cat => cat.id_Parent === rootCategory.id);
        if(childCategories.length > 0) {
          return {
            label: rootCategory.title,
            id: rootCategory.id,
            disabled: false,
            choices: childCategories.map(child => ({
              value: child.id,
              label: child.title,
              selected: this.data.currentCategory && child.id === this.data.currentCategory.id,
            }))
          };
        }
        else {
          return {};
        }
      });

      tree.setChoices(choicesData, 'value', 'label', false);
    },
    changeCategory(e) {
      if(e.target.value === '')
        this.$router.push('/podcasts');
      else
        this.$router.push('/podcasts/category/' + e.target.value);
    }
  },
  computed: {
    getCategoryTitle() {
      if(this.data.currentCategory)
        return this.data.currentCategory.title;
      return "All podcasts";
    },
    isAuth: function() {
      return localStorage.getItem('user.id') !== null;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('t-podcasts-title')" :description="$t('t-podcasts-description')">
      <div>
        <a href="/create/podcast" class="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target=".createPodcast" v-if="isAuth"> Create podcast </a>
        <div class="d-inline-block">
          <select @change="changeCategory" class="form-control" id="choices-podcasts-categories" data-choices data-choices-groups :data-placeholder="getCategoryTitle">
            <option value="">All podcasts</option>
          </select>
        </div>
      </div>
    </PageHeader>

    <div class="row">
      <div class="col-lg-12">
        <div class="team-list grid-view-filter row">
          <div class="col" v-for="p in data.list" v-bind:key="p">
            <PodcastItem v-bind:data="p"></PodcastItem>
          </div>
        </div>
      </div>
    </div>

    <Create></Create>

  </Layout>
</template>